<template>
    <div :is="!state==true?layout:'div'">
           <div  class="d-flex justify-space-between align-center">
                 <div class="d-flex justify-start align-center ml-4">
                <span class="pr-1">{{$t('flight.switch_view')}}: </span>
                <v-radio-group @change="getGanttConfigByZoomValue(radioGroup)" v-model="radioGroup"  row>
                <v-radio :label='$t("flight.year")' value="year"></v-radio>
                <v-radio :label='$t("flight.month")' value="month"></v-radio>
                <v-radio :label='$t("flight.day")'  value="day"></v-radio>
                <v-radio :label='$t("flight.hour")' value="hour"></v-radio>
                <v-radio :label='$t("flight.minute")' value="minute"></v-radio>
                </v-radio-group>
            </div>
             <div v-if="!state==true">
            <v-btn  style="margin: 10px" color="accent" @click="submit" small>{{$t('flight.confirm')}}</v-btn>
            <v-btn  style="margin: 10px;margin-right:30px" @click="cancle"  color="accent" small>{{$t('flight.cancel')}}</v-btn>
        </div>
           </div>

            <div id="gantt_here" :style='!state==true?"width:100%;overflow:auto; height:calc(100vh - 170px)":"width:100%; height:calc(100% - 70px)"'></div>

    </div>
</template>
<script>

    import gantt from 'dhtmlx-gantt'  // 引入模块
    import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
    import { get_gantt_data,patch_gantt_data } from "../../../api/flight";
    // import gantt_flight from './seatdata'
    export default {
        name: 'flightpreview',
        // components: {
        // gantt_flight,
        // },
        props:{
        state: {type: Boolean},
        start_time:{type:String},
        end_time:{type:String}
        },
        data: () => ({
            data: null,
            result:null,
            layout: null,
            airport_id: null,
            origin:null,
            changList:[],
            task:null,
            flag:false,
            boolean:null,
            aircraftnode_list:[],
            currentDate:null,
            radioGroup:'hour',
            English:{
                labels:{
                    column_text: "Name",
                    column_start_date: "Start time",
                    column_end_date: "End time",
                    column_start_flight: "Former Flight",
                    column_end_flight: "Flight Number",
                    }},
            Chinese: {
                labels:{
                    column_text: "名称",
                    column_start_date: "开始时间",
                    column_end_date: "结束时间",
                    column_start_flight: "前序航班号",
                    column_end_flight: "航班号",
                    }}
        }),
        methods: {
            // 点击切换时间
            getGanttConfigByZoomValue (value) {
                switch (value) {
                     case 'minute':
                        gantt.config.scales = [
                        {unit: "day", step: 1, format: "%Y-%m-%d "},
                        {unit: "minute", step:1, format: "%H:%i" },
                        ];
                        gantt.config.scale_height =  27;
                        break
                        case 'hour':
                        gantt.config.scales = [
                        {unit: "day", step: 1, format: "%Y-%m-%d "},
                        {unit: "hour", step:1, format: "%H" },
                        ];
                         gantt.config.scale_height =  27;
                        break
                        case 'day':
                        gantt.config.scales = [
                        {unit: "year", step: 1, format: "%Y"},
                        {unit: "day", step: 1, format: "%d %M"}];
                        gantt.config.scale_height =  27;
                        break
                        case "month":
                        gantt.config.scales = [
                        {unit: "month", step: 1, format: "%F, %Y"},
                        ];
                        gantt.config.scale_height = 50;
                        break;
                        case "year":
                        gantt.config.scales = [
                            {unit: "year", step: 1, format: "%Y"},
                        ];
                        gantt.config.scale_height = 50;
                        break;
                }

                //   gantt.resetLightbox();
                  gantt.render();
                },
           async  submit(){
                if(this.changList.length&&this.flag){
                this.boolean=true;
                var count=0
                for(let i=0;i<this.changList.length;i++){
                if(this.boolean){
                    var item=this.changList[i]
                   await  patch_gantt_data(item).then(res => {
                     count++
                    if(count==this.changList.length){
                    this.changeColor()
                    this.$toast({
                    title:  this.$t('success'),
                    message: this.$t('flight.edit_success')
                    })
                    this.result=JSON.parse(JSON.stringify(this.origin))
                    gantt.render();
                  }
                  }).catch(e=>{
                        this.$toast({
                        title:this.$t('error'),
                        message:this.$t('flight.edit_error'),
                        type: 'warning'
                })
                this.boolean=false
                })
                }
                }
                this.flag=false
                }else{
                    this.$toast({
                        title:this.$t('error'),
                        message:this.$t('flight.edit_none'),
                        type: 'warning'
                })
                }
            },
            cancle(){
                if(this.flag){
                let regain=JSON.parse(JSON.stringify(this.result))
                    regain.forEach((item,index)=>{
                    if(item.slipping_time){
                    regain[index].start_date=new Date(item.start_date)
                    regain[index].end_date=new Date(item.end_date)
                    regain[index].slipping_time=new Date(item.slipping_time)
                        }else{
                    delete regain[index].start_date
                    delete regain[index].end_date
                    delete regain[index].slipping_time
                        }
                })
                this.tasks = {data: regain};
                gantt.parse(this.tasks);
                this.changeColor()
                this.flag=false
                }

            },
            changeColor(){
                const group = [];
                this.tasks.data.forEach((item) => {
                const parent = group.find((cur) => cur.parent === item.parent);
                if (parent) parent.childs.push(item);
                 else {
                    const obj = {
                    parent: item.parent,
                    childs: [item],
                    };
                    group.push(obj);
                }
                });
                let index = group.findIndex( element => {
                if (element.parent === 0) {
                    return true;
                }
                });
                    group.splice(index,1)
                    group.forEach((item) => {
                    item.childs.sort((a,b)=>{
                        return a.start_date-b.start_date
                        })})
                    group.forEach((item) => {
                    for(let i=0;i<item.childs.length;i++){
                    for(let j=i+1;j<item.childs.length;j++){
                        // 航班有冲突
                    if(this.compareDate(item.childs[i].end_date,item.childs[j].start_date)===0) {
                        gantt.getTask(item.childs[i].id).color = "rgba(255,0,0,0.6)"
                        gantt.getTask(item.childs[i+1].id).color = "rgba(255,0,0,0.6)"
                        gantt.updateTask(item.childs[i].id)
                        gantt.updateTask(item.childs[i+1].id)
                    }else if(this.compareDate(item.childs[i].end_date,item.childs[j].start_date)===2){
                        // 时间相距较近
                        gantt.getTask(item.childs[i].id).color = "rgb(255,215,0)"
                        gantt.getTask(item.childs[i+1].id).color = "rgb(255,215,0)"
                        gantt.updateTask(item.childs[i].id)
                        gantt.updateTask(item.childs[i+1].id)
                    }
                    else if(this.compareDate(item.childs[i].end_date,item.childs[j].start_date)===1){
                         gantt.getTask(item.childs[i].id).color = "#299cb4"
                        gantt.getTask(item.childs[i+1].id).color = "#299cb4"
                        gantt.updateTask(item.childs[i].id)
                        gantt.updateTask(item.childs[i+1].id)
                    }
                    }
                    }
                 });

            },
            getDate(datetime) {
               function addDateZero(num) {
                    return num < 10 ? "0" + num : num;
                }
                let d = new Date(datetime);
                let formatdatetime =
                    d.getFullYear() +
                    "-" +
                    addDateZero(d.getMonth() + 1) +
                    "-" +
                    addDateZero(d.getDate()) +
                    " " +
                    addDateZero(d.getHours()) +
                    ":" +
                    addDateZero(d.getMinutes())
                return formatdatetime;
            },
            addchangList(id,middle){
                if(this.changList.length){
                var result1 = this.changList.some(item=>{
                if(item.id==id&&item.type==middle.type) return true })
                if(result1){
                this.changList.forEach((item,index)=>{
                if(item.id==id&&item.type==middle.type) this.changList[index]=JSON.parse(JSON.stringify(middle))
                 })
                }else this.changList.push(middle)
                }else this.changList.push(middle)
        },
             // 比较时间大小
            compareDate(dateTime1,dateTime2){
            var formatDate1 = new Date(dateTime1)
            var formatDate2 = new Date(dateTime2)
            if(formatDate1 > formatDate2) return 0;
            else if(((formatDate2.getTime()-formatDate1.getTime())/1000/60)<=10) return 2;
            else return 1;
        },
            init() {
                this.data.results.forEach(item=>{
                    if(item.slipping_time)   item.slipping_time=new Date(item.slipping_time)
                     // 显示滑行时间
                        const fly_time=(new Date(item.slipping_time).getTime()-new Date(item.start_date).getTime())/1000;
                        const duration1=(new Date(item.end_date).getTime()-new Date(item.start_date).getTime())/1000
                        item.progress=fly_time/duration1
                })
                 this.tasks = {
                    data: this.data.results
                };
                this.result=JSON.parse(JSON.stringify(this.data.results))
                gantt.config.open_split_tasks = true;
                // 去除删除按钮
                // gantt.config.buttons_right = [];
                // 去除错误提示
                // gantt.show_errors=false
                // 语言
                 if (this.$i18n.locale === 'zh') {
                   gantt.i18n.setLocale(this.Chinese)
                } else if (this.$i18n.locale === 'en') {
                    gantt.i18n.setLocale(this.English)
                }
                gantt.config.date_format = "%Y-%m-%d %H:%i";//设置数据中的时间格式，对应start_date格式
                gantt.config.columns = [//设置列
                    {name:"text",        width:"120",  align:"center", tree:true },
                    {name:"start_date", width:"110", align:"center" },
                    {name:"end_date",  width:"110", align:"center" },
                    {name: "start_flight", width: "100", align: "center",
                    template:function(obj){
                        if(obj.start_flight)  return obj.start_flight['number']
                    }},
                    {name: "end_flight",  width: "100", align: "center",
                    template:function(obj){
                        if(obj.end_flight)  return obj.end_flight['number']
                    }}
                ];
                // 修改默认图标
                var father_icon=require('../../../assets/apron.png');
                gantt.templates.grid_folder = function(item) {
                return `<div class='gantt_tree_icon gantt_folder_' style='background:url(${father_icon});background-size:75% 60%;background-position:50% 50%; background-repeat:no-repeat'></div>`;
                };
                var child_icon=require('../../../assets/aircraft.png');
                gantt.templates.grid_file = function(item) { return `<div class='gantt_tree_icon' style='background:url(${child_icon});background-size:75% 60%;background-position:50% 50%; background-repeat:no-repeat'></div>`; };
                // 修改右侧网格中的文本
                // gantt.templates.task_text=function(start, end, task){return task.text;};
                gantt.plugins({
                    tooltip: true,//鼠标划过任务是否显示明细
                    // auto_scheduling: true,//根据任务之间的关系自动安排任务
                    multiselect: true, //为任务激活多任务选择
                    marker: true  //标记
                });
                // 修改lightbox中的内容
                gantt.locale.labels.section_start_date = "start_date";
                gantt.locale.labels.section_name = "Flight Name";
                gantt.locale.labels.section_end_date = "end_date";
                // gantt.locale.labels.section_slipping_time = "slipping_time";
                gantt.config.lightbox.sections = [
                {name: "name",map_to: "text",height:38,type: "textarea"},
                {name: "start_date",map_to: "start_date",type: "duration",time_format: ["%Y", "%m", "%d", "%H:%i"],single_date:true},
                // {name: "slipping_time",map_to: "slipping_time",time_format: ["%Y", "%m", "%d", "%H:%i"], type: "duration",single_date:true},
                 {name: "end_date",map_to: "end_date",type: "duration",time_format: ["%Y", "%m", "%d", "%H:%i"],single_date:true},
                ];
                gantt.config.scale_height = 27;
                // 设置任务时间值的最小步长
                gantt.config.time_step = 1;
                gantt.config.duration_unit = "minute";
                gantt.config.duration_step = 1;
                gantt.templates.tooltip_date_format=function (date){
                var formatFunc = gantt.date.date_to_str("%Y-%m-%d %H:%i");
                return formatFunc(date);
            };
             gantt.attachEvent("onMouseMove", function (t,e){
              gantt.templates.tooltip_text = function (start, end, task) {
                  if(task.slipping_time&&(e.clientX-ganttdiv.getBoundingClientRect().left)> gantt.config.grid_width){
                      let slipping_time=new Date(task.slipping_time)
                    return '<b>航班编号:</b> ' + task.text + "<br/><b>滑行时间:</b> " +gantt.templates.tooltip_date_format(start)
                    +" - " +gantt.templates.tooltip_date_format(slipping_time)
                     + '<br/><b>停机时间:</b> ' + gantt.templates.tooltip_date_format(slipping_time)+' - '+ gantt.templates.tooltip_date_format(end)
                  }else if(!task.slipping_time&&(e.clientX-ganttdiv.getBoundingClientRect().left)> gantt.config.grid_width){
                       return '<b>停机坪编号:</b> ' + task.text+ '<br/><b>使用时间:</b> ' + gantt.templates.tooltip_date_format(start)+' - '+ gantt.templates.tooltip_date_format(end)
                  }
                }
             })
                // 不允许四舍五入
                gantt.config.round_dnd_dates = false;
                // 设置最小间隔
                gantt.config.min_duration = 1*60; // (1min)
                    if(this.state){
                    gantt.config.readonly = true
                    gantt.config.start_date = new Date(this.start_time);
                    gantt.config.end_date = new Date(this.end_time);
                }else{
                    gantt.config.readonly = false
                    gantt.config.start_date = new Date(this.data.start_time);//时间刻度的开始时间
                    gantt.config.end_date = new Date(this.data.end_time);//时间刻度的结束时间
                }
                gantt.config.drag_links = false; //禁止拖动连接
                gantt.config.drag_progress = false;//禁止拖动进度条
                gantt.config.date_grid = "%Y/%m/%d %H:%i";
                gantt.config.order_branch = "marker";
                // gantt.config.order_branch_free = true;
                gantt.config.open_tree_initially = true;
                // 调整网格字体颜色
                gantt.templates.task_text = function(start, end, task){
                    return "<span style='color:black;float:left'>"+task.text+"</span>";
                };
                // gantt.config.grid_elastic_columns = false;
                var ganttdiv=document.getElementById('gantt_here')
                // gantt.config.grid_width=340
                // 甘特图会自动延长以适应所有的任务
                gantt.config.fit_tasks = true;

                gantt.init("gantt_here");
                 gantt.clearAll()
                gantt.parse(this.tasks);
                // gantt.render()
                // 点击lightbox的save按钮时，修改数据
                gantt.attachEvent("onLightboxSave", (id, task, is_new)=>{
                    var arr={}
                    var flyTime=null
                    this.result.forEach(item=>{
                    if(item.id==id){
                            flyTime=(new Date(item.slipping_time).getTime()-new Date(item.start_date).getTime())
                            task.slipping_time=new Date(task.start_date.getTime()+flyTime)
                            arr.type=1
                            arr.first={
                                time:this.getDate(gantt.getTask(id).start_date),
                                id:gantt.getTask(id).start_flight.id
                            }
                            arr.last={
                                time:this.getDate(gantt.getTask(id).end_date),
                                id:gantt.getTask(id).end_flight.id
                            }
                        }
                })
                    patch_gantt_data(arr).then(res => {
                    this.$toast({
                    title:  this.$t('success'),
                    message: this.$t('flight.edit_success')
                    })
                    var min_date = gantt.getState().min_date;
                    var max_date = gantt.getState().max_date;
                    // 超出范围时，修改最大日期和最小日期
                    if (min_date >= task.start_date) {
                    gantt.config.start_date = min_date = gantt.date.add(task.start_date, 0, gantt.config.duration_unit);
                    gantt.render()
                    }
                    if (max_date<= task.end_date) {
                    gantt.config.end_date = max_date = gantt.date.add(task.end_date, 0, gantt.config.duration_unit);
                    gantt.render()
                    }
                    this.result=JSON.parse(JSON.stringify(this.tasks.data))
                    this.changeColor()
                    })
                return true
                });
                 // 拖拽之后 修改数据
                gantt.attachEvent("onAfterTaskDrag", (id,mode,e)=>{
                    // var modes = gantt.config.drag_mode;
                    var middle={}
                    this.flag=true
                    let getTask=gantt.getTask(id)
                    let flyTime=null;
                    this.result.forEach(item=>{
                    if(item.id==id){
                    // if(mode!=="progress"){
                            flyTime=(new Date(item.slipping_time).getTime()-new Date(item.start_date).getTime())
                            getTask.slipping_time=new Date(getTask.start_date.getTime()+flyTime)
                    // }else{
                    //     flyTime=getTask.progress*(getTask.end_date.getTime()-getTask.start_date.getTime())
                    //     getTask.slipping_time=new Date(new Date(item.start_date).getTime()+flyTime)
                    // }
                    middle.type=1
                    middle.id=id
                    middle.first={
                        time:this.getDate(gantt.getTask(id).start_date),
                        id:gantt.getTask(id).start_flight.id
                    }
                    middle.last={
                        time:this.getDate(gantt.getTask(id).end_date),
                        id:gantt.getTask(id).end_flight.id
                    }
                }
                })
                    this.addchangList(id,middle)
                    this.origin=this.tasks.data
                    return true
                });
                this.getGanttConfigByZoomValue('hour')
                // 拖动左侧的任务栏
                 var old_parent;
                 var newParent;
                gantt.attachEvent("onRowDragStart", function(id, target, e) {
                old_parent = gantt.getTask(id).parent;
                return true;
                });
                gantt.attachEvent("onBeforeRowDragEnd", (id, parent, tindex)=>{
                var getTask = gantt.getTask(id);
                newParent =parent;
                if(old_parent!=newParent){
                this.flag=true
                gantt.moveTask(id,tindex,newParent);
                gantt.updateTask(getTask.id);
                var middle={
                    type:2,
                    id:id,
                    first_flight_id:gantt.getTask(id).start_flight.id,
                    last_flight_id:gantt.getTask(id).end_flight.id,
                    seat:newParent
                }
                this.addchangList(id,middle)
                }
                return true
                });
                if(this.state){
                 var current_time = new Date(this.data.start_time);
                 var todayMarker = gantt.addMarker({
                    start_date: current_time,
                    css: "start_project",
                    text: 'Start',
                    });
                // 点击空白区域 移动标记
                gantt.attachEvent("onEmptyClick", (e)=>{
                if(gantt.getMarker(todayMarker)){
                let marker=gantt.getMarker(todayMarker)
                let mouseDate = gantt.dateFromPos(e.clientX+gantt.getScrollState().x -ganttdiv.getBoundingClientRect().left- gantt.config.grid_width)
                if (mouseDate) {
                   marker.start_date = mouseDate;
                   marker.text = gantt.date.date_to_str(gantt.config.date_format)(mouseDate);
                   this.currentDate=marker.text
                   this.$emit("change",`${this.currentDate}_${Math.random()}`)
                   gantt.renderMarkers();
                }
                }
                });
                }
                // 拖拽超过开始和结束日期
                gantt.attachEvent("onTaskDrag", function(id, mode, task, original){
                    if(mode!="progress"){
                    var min_date = gantt.getState().min_date;
                    var max_date = gantt.getState().max_date;
                    if (task.start_date.getTime()==min_date.getTime()) {
                    gantt.config.start_date = min_date = gantt.date.add(min_date, -1, gantt.config.duration_unit);
                    gantt.render();
                    gantt.showDate(min_date);
                    }
                    if (task.end_date.getTime()==max_date.getTime()) {
                    gantt.config.end_date = max_date = gantt.date.add(max_date, 1, gantt.config.duration_unit);
                    gantt.render()
                    }
                    }
                });
                this.changeColor()
            }
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            if (!this.$route.query.airport_id && !this.$store.state.select_air_port_id) {
                this.$toast({
                    title: this.$t('error'),
                    message: this.$t('flight.error_mes'),
                    type: 'warning'
                })
                return this.$router.push('/')
            }
            this.airport_id = this.$route.query.airport_id || this.$store.state.select_air_port_id
            const closeLoading = this.$loading()
            get_gantt_data({type:this.$route.query.type,start_time:this.$route.query.start_time,end_time:this.$route.query.end_time, airport_id: this.airport_id}).then(res => {
                this.data = res.data
                this.$nextTick(() => {
                    this.init();
                })
            }).finally(() => {
                closeLoading()
            })
        },
        watch:{
        '$i18n.locale'(newValue) {
                if (this.$i18n.locale === 'zh') {
                    gantt.i18n.setLocale(this.Chinese)
                    gantt.render()
                } else if (this.$i18n.locale === 'en') {
                    gantt.i18n.setLocale(this.English)
                    gantt.render()
                }
            },
            immediate:true
        },
        // destroyed() {
        // gantt.templates.tooltip_text = function(){return "";}
        // gantt.render()
        destoryed(){
            gantt.destructor();
        }
    }
</script>

<style lang="stylus" scoped>
    >>> #gantt_here
        .gantt_scale_cell
            margin-left: -35px;

        .gantt_data_area, .gantt_task_cell, .gantt_task_scale, .gantt_grid_data, .gantt_row gantt_row_task, .gantt_row, .gantt_row_task, .gantt_grid_scale *
            background var(--v-secondary2-base)
        & *
            color var(--v-primary-base)
            border-color var(--v-primary-base)
        .gantt_marker
            background rgba(0,255,0,0.5)
        .gantt_task_progress
             background-color: orange !important;
             opacity: 1.0

        .drag_handle
            background-image: radial-gradient(plum, orange,white);
            opacity: 0.2;
            width: 60px;
            height: 34px;


        .task_drag
            float:left;
            width: 100%;



</style>
