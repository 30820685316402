import { request } from '@/utils'


// 添加航班信息
export const add = data => request.post('/api/flight/', data)

// 获取航班信息
export const get = params => request.get('/api/flight/', { params })

// 修改航班信息
export const edit = data => request.patch(`/api/flight/${data.id}/`, data)

// 删除航班信息
export const del = id => request.delete(`/api/flight/${id}/`)


// 获取甘特图数据
export const get_gantt_data = params => request.get('/api/flight_gantt/',{params})

// 修改甘特图数据
export const patch_gantt_data = data => request.patch('/api/flight_gantt/',data)

// 导入文件
export const import_file = data => request.post(`/api/flight/${data.id}/upload_file/`, data)


